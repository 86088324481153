import Layout from "layout";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import {
  useBoardList,
  deletePost
} from "utils/api/board.api";
import toast from "react-hot-toast";
import 'reactjs-popup/dist/index.css';

export default function Boards() {
   
  const { data, isLoading } = useBoardList();
  const { mutate: onDeleteBoard, isSuccess} = deletePost();

  useEffect(() => {
    if (isSuccess) {
      toast.success("Board deleted successfully!");

    }
  }, [isSuccess]);

  function replaceATagWithSpanAndRemoveImg(html : string) {
    // a 태그를 span 태그로 변환
    const withoutATag = html.replace(/<a\b[^>]*>(.*?)<\/a>/gi, '<span>$1</span>');
    // img 태그를 제거
    return withoutATag.replace(/<img\b[^>]*>/gi, '');
  }
    
  const deleteBoard = ( _id : string ) => {
    
    const isConfirmed = window.confirm("Are you sure you want to delete this board?");

    if (isConfirmed) {
      // 사용자가 'OK'를 누르면 삭제 처리
      onDeleteBoard({id:_id});
      
      // 실제 삭제 작업을 여기에 추가 (API 호출 등)
    } else {
      // 'Cancel'을 누르면 작업 취소
      toast.success("Board deletion canceled.");
    }

  };

  return (
    <Layout>
      <div className="dashboard-main">
        <div className="main-wrapper max-w-5xl p-6">
          <ul className="flex flex-wrap items-center gap-3 mb-4">
            <li>
              <p className="text-black-900 text-xl">Boards</p>
            </li>
            <li>
              <NavLink
                  to="/boardwrite" className="hover:bg-orange-300 bg-orange-500 border border-cyan-300 rounded-lg text-black-700 text-base font-bold transition px-8 lg:px-16 py-3">
                New Board
              </NavLink>
            </li>
          </ul>

          <div className="table-wrapper max-h-[48rem] overflow-auto">
            <table className="w-full">
              <thead>
                <tr>
                  <td>Number</td>
                  <td>Image</td>
                  <td className="classTitletd" >Title</td>
                  <td>Content</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
              {data
                  ?.map((item, key) => <tr key={item._id} style={{borderTop :'1px solid #eee'}}>
                    <td className="classNametd">{key+1}</td> 
                    <td className="classNametd"> <img alt="" src={item.image===null?"/images/default_img01.jpg":item.image}/></td> 
                    <td className="classTitletd" >{item.title}</td> 
                    <td className="classContent" dangerouslySetInnerHTML={ {__html: replaceATagWithSpanAndRemoveImg(item.content)} }></td> 
                    <td className="classNametd">
                      <NavLink
                          to={`/boardwrite?id=${String(item._id)}`}  className="hover:bg-blue-300 bg-blue-500 rounded transition px-2 lg:px-5 py-2">
                        Edit
                      </NavLink>
                      <button className="hover:bg-red-300 bg-red-500 rounded transition px-2 lg:px-5 py-2 mt-2" onClick={() => deleteBoard(item._id)}>
                          Delete
                      </button>
                    </td>
                  </tr>)}
                {(!isLoading && data?.length === 0) &&
                    <tr>
                      <td>No Results Found</td>
                    </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
}
