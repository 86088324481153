import Layout from "layout";
import { useState, useEffect } from "react";
import { getBoard, writeBoard } from "utils/api/board.api";
import imageCompression from "browser-image-compression";
// import type { ContentBlock } from 'draft-js';
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";

/*
function imageBlockRenderer(contentBlock: ContentBlock) {
  const type = contentBlock.getType();
  console.log(type);
  // Convert image type to mediaComponent
  if (type === "atomic") {
    return {
      component: MediaComponent,
      editable: false,
      props: {
        foo: "bar",
      },
    };
  }
}

function MediaComponent({ block, contentState, blockProps: { foo } }: any) {
  const data = contentState.getEntity(block.getEntityAt(0)).getData();
  const emptyHtml = " ";
  return (
    <div>
      {emptyHtml}
      <img
        src={data.src}
        alt={data.alt || ""}
        style={{ height: data.height || "auto", width: data.width || "auto" }}
      />
    </div>
  );
}
*/
export default function BoardWrite()  {
  const navigate = useNavigate();
  const [imageFile, setImageFile] = useState<File>()
  const [title, setTitle] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const boardId = searchParams.get("id");
  
    if (boardId !== null && boardId !== "") {
      getBoard(boardId)
        .then((data) => {
          setTitle(data.title);
          setContent(data.content)
          /*
          const blocksFromHtml = htmlToDraft(data.content);
          if (blocksFromHtml) {
            const { contentBlocks, entityMap } = blocksFromHtml;
            // https://draftjs.org/docs/api-reference-content-state/#createfromblockarray
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            // ContentState를 EditorState기반으로 새 개체를 반환.
            // https://draftjs.org/docs/api-reference-editor-state/#createwithcontent
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);
          }
            */
          
        })
        .catch((error) => {
          console.error("Error fetching board data:", error);
        });
    }
  }, [searchParams]);
  
  const { mutate: write} = writeBoard();
  /*
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty())
  const onEditorStateChange = (editorState1: any) => {
    setEditorState(editorState1);
  };
  */
  const changeEvent = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setTitle(e.target.value)
  };

  const handleChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setContent(event.target.value);
  };

  const submitEvent = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

      if (typeof imageFile === 'object'){
        actionImgCompress(imageFile).then((result) => {
        //  console.log(result);
        })
        .catch((error) => {
          console.error("Error occurred:", error);
        });
      } else {
        toast.error('대표 image는 필수입니다.');
      }
  };

  const handleChangeFile = (e : any) => {

    const file = e.target.files?.[0];

    if(file){
        setImageFile(file);
    }
  }

  const actionImgCompress = async (fileSrc :File) => {
    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(fileSrc, options);

      const reader = new FileReader();
      reader.readAsDataURL(compressedFile);
      reader.onloadend = async () => {
        const base64data = reader.result;
        
        try {
          const senddata = { 
            id : searchParams.get("id"),
            title,
            content,
            image : base64data
          };
          write(senddata);
          navigate("/boards");
        } catch (error) {
          console.log(error);
        }

      }
    } catch (error) {
      console.log(error);
    }
  };

/*
  const uploadImageCallBack = async (fileSrc : File) => {

    const formData = new FormData();
    formData.append('file', fileSrc);

    const response = await fetch('/api/fileupload', {
    method: 'POST',
    body: formData
    });

    const body = await response.json() as { status: 'ok' | 'fail', message: string, filename:string };

    if (body.status === 'ok') {
      return await Promise.resolve({ data: { 
        link: body.filename
      }});
    } else {
        toast.error(body.message);
    }
  }
  */
  return (
    <Layout>
      <div className="dashboard-main">
        <div className="main-wrapper max-w-5xl p-6">
          <ul className="flex flex-wrap items-center gap-3 mb-4">
            <li>
              <p className="text-black-900 text-xl">New Board</p>
            </li>
          </ul>
          <div className="table-wrapper max-h-[48rem] overflow-auto">
          <form 
              method="post"
              encType="multipart/form-data"
              onSubmit={submitEvent}
            >
            <table className="w-full">
              <tbody>
                <tr>
                  <td>
                    Title
                  </td>
                   <td className="w-full">
                   <input
                      onChange={changeEvent}
                      name="title"
                      value={title}
                      placeholder="Input Title"
                      type="text"
                      className="block basis-12/12 rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                   </td>
                </tr>
                <tr>
                  <td>Image</td>
                  <td> 
                    <input
                      type="file" 
                      accept="image/jpg, image/jpeg, image/png"
                      name='mainImage' 
                      onChange={handleChangeFile}/>
                  </td>
                </tr>
                <tr>
                  <td>
                      Content
                    </td>
                    <td className="w-full">
                    <textarea
                      value={content}
                      className="tdtextArea"
                      onChange={handleChange}
                      placeholder="Type your text here"
                      rows={15}
                      cols={120}
                    />
                   </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <button className="btn-gradient cyan" type="submit">Write</button>
                  </td>
                </tr>
              </tbody>
            </table>
            
            
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
}
