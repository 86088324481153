import { useQuery, useMutation } from "@tanstack/react-query";
import { client } from "./api-client";

import {
  type Board,
} from "utils/types/user.type";

export const useBoardList = (isAdmin?: boolean) =>
  useQuery<Board[], Error>(
    ["getBoardList"],
    async () =>
      await client(`board/GetAll`, { }), {
        onSuccess() {
        }, 
        onError: (error) => {
          console.log(error);
        },
    });
  

export const getBoard = async (boardId: string) => {
      return await client(`board/GetOne/${boardId}`);
    };


export const writeBoard = () => {
  return useMutation<
    unknown,
    Error,
    { title: string; image: string | ArrayBuffer | null; content : string }
  >(async (data) => await client(`board/WritePost`, { data }), {
    onSuccess() {
    }, 
    onError: (error) => {
      console.log(error);
    },
  });
};

export const deletePost = () => {
  return useMutation<unknown, Error, { id: string; }>(
    async (data) => await client(`board/deletePost`, { data }),
    {
    },
  );
};


